<template>
  <div>
    <b-card
      class="shadow p-2"
      style="padding-top:0.5rem !important;"
    >
      <b-overlay :show="$apollo.loading || mutationLoading">
        <b-row>
          <b-col md="2">
            <b-card class="d-flex flex-column">
              <h4 class="text-center mb-1">
                Documents
              </h4>
              <b-button
                v-for="(button, index) in buttons"
                :key="index"
                block
                :variant="button.id == selectedBd ? 'primary' : 'outline-primary'"
                @click="selectedBd = button.id"
              >
                {{ button.title }}
              </b-button>
            </b-card>
          </b-col>
          <b-col
            class=""
            md="10"
          >
            <b-card>
              <b-tabs class="">
                <div class="d-flex justify-content-between align-items-center ">
                  <b-button
                    v-b-modal.knowledgeBaseModal
                    variant="outline-primary"
                    class="mr-2"
                  >
                    <feather-icon icon="PlusIcon" />
                    Add New Version
                  </b-button>
                  <div>
                    <b-button
                      variant="outline-primary"
                      :disabled="true"
                    >
                      <feather-icon icon="EyeIcon" />
                      View Sample
                    </b-button>
                  </div>
                </div>
                <!-- table -->
                <vue-good-table
                  class="mt-1"
                  :columns="columns"
                  :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                  }"
                  :rows="vbd"
                >
                  <!-- pagination -->
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'action'">
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-1"
                        :href="`/startup-portal/business-details/${selectedBd}/evaluation/${props.row.id}/${props.row.title}`"
                      >
                        <span>Evaluation</span>
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-1"
                      >
                        <span>Verification</span>
                      </b-button>
                      <Promised
                        :promise="getLinkFromS3(props.row.file)"
                      >
                        <template #default="data">
                          <b-button
                            size="sm"
                            variant="flat-primary"
                            class="mr-1"
                            :href="data"
                            target="_blank"
                            download
                          >
                            <feather-icon
                              icon="EyeIcon"
                            />
                          </b-button>
                        </template>
                      </Promised>
                      <b-button
                        size="sm"
                        variant="flat-danger"
                        class="mr-1"
                        @click="selectedIndex=props.row.id;deleteversion()"
                      >
                        <feather-icon
                          icon="TrashIcon"
                        />
                      </b-button>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :per-page="pageLength"
                          :total-rows="props.total"
                          :value="1"
                          align="right"
                          class="mt-1 mb-0"
                          first-number
                          last-number
                          next-class="next-item"
                          prev-class="prev-item"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
      <b-modal
        id="knowledgeBaseModal"
        ok-only
        no-close-on-backdrop
        ok-title="Upload"
        size="lg"
        title="Add New Version"
        @ok="addNewVersion"
      >
        <b-overlay :show="$apollo.loading || mutationLoading">
          <validation-observer
            ref="uploadForm"
            tag="form"
          >
            <b-row>
              <b-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-group
                    label="Title"
                    label-size="sm"
                  >
                    <b-form-input
                      v-model="title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Title"
                      required
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Description"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="description"
                    placeholder="File Description"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <!-- primary -->
                <div class="d-flex align-items-center">
                  <span>Upload File?</span>
                  <b-form-checkbox
                    v-model="is_internal"
                    class="ml-50"
                    inline
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="UploadIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="LinkIcon" />
                    </span>
                  </b-form-checkbox>
                </div>
                <hr>
              </b-col>
              <b-col
                v-if="is_internal"
                cols="12"
              >
                <h6>Upload File</h6>
                <!-- Styled -->
                <validation-provider
                  v-slot="{ errors }"
                  name="File"
                  rules="size:10000"
                >
                  <b-form-file
                    v-model="file"
                    :state="errors.length > 0 ? false:null"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                v-else
                cols="12"
              >
                <h6>External Link</h6>
                <b-form-input
                  v-model="file_link"
                  placeholder="https://"
                />
              </b-col>
            </b-row>
          </validation-observer>
        </b-overlay>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, size } from '@core/utils/validations/validations'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import { Promised } from 'vue-promised'
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    VueGoodTable,
    BPagination,
    Promised,
    BFormFile,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      pageLength: 5,
      selectedBd: null,
      buttons: [],
      vbd: [],
      columns: [
        {
          label: 'V',
          field: 'version',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Badge',
          field: 'badge',
        },
        {
          label: 'Date Added',
          field(row) {
            const dateNew = new Date(row.created_at)
            const options = { day: 'numeric', month: 'long', year: 'numeric' }
            const formattedDate = dateNew.toLocaleDateString('en-US', options)
            return formattedDate
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      title: null,
      description: '',
      version: null,
      is_internal: true,
      file_link: null,
      file: null,
      selectedIndex: null,
      mutationLoading: false,
      size,
      required,
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
      userDetails: 'user/getUserDetails',
    }),
  },
  methods: {
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    async addNewVersion() {
      this.mutationLoading = true
      const fileLink = this.file ? await this.uploadFile(this.file).catch(() => {
        this.showAlert('Error uploading file!', 'Error')
      }) : this.file_link
      const mutation = gql`
        mutation {
          insert_versions_business_details_one (
            object: {
              file: "${fileLink}",
              startup: ${this.selectedOrg.orgId},
              title: ${this.title},
              description: ${this.description},
              business_details: ${this.selectedBd},
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully added new version.',
            icon: 'CompassIcon',
            variant: 'success',
          },
        })

        this.$apollo.queries.vbd.refetch()
        this.mutationLoading = false
        this.title = null
        this.description = null
        this.file = null
        this.file_link = null
        this.is_internal = true
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong. Please try again later.',
            message: error.message,
            icon: 'CompassIcon',
            variant: 'danger',
          },
        })
        this.mutationLoading = false
      })
    },
    deleteversion() {
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this version?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete Version',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                      update_versions_business_details_by_pk(pk_columns: {id: ${this.selectedIndex}}, _set: {is_deleted: true}) {
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.vbd.refetch()
                },
              },
            )
          }
        })
    },
  },
  apollo: {
    vbd: {
      query() {
        return gql`
          query Vbd {
            versions_business_details(where: {startup: {_eq: ${this.selectedOrg.orgId}}, business_details: {_eq: ${this.selectedBd}}}, order_by: {id: desc}) {
              file
              id
              title
              created_at
              version
              description
            }
          }`
      },
      update: data => data.versions_business_details,
    },
    buttons: {
      query() {
        return gql`
          query Buttons {
            business_details(where: {is_default: {_eq: true}}) {
              id
              title
            }
          }`
      },
      update(data) {
        this.selectedBd = data.business_details[0].id
        return data.business_details
      },
    },
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
